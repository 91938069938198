import React from "react"
import Footer from "../../../../common/footer/Footer"
import s from "./ServicesSectionStyle.module.styl"
import cloud from "../../../../assets/images/services/cloud.svg"
import rocket from "../../../../assets/images/services/rocket.svg"
import scroll from "../../../../assets/images/services/scroll.svg"
import cogbulb from "../../../../assets/images/services/cogbulb.svg"

const data = [
  {
    image: cloud,
    title: "Strategy & Advisory",
    color: "#FF0301",
    description: [
      "Technology Strategy",
      "Software Architecture",
      "Project Management",
      "Business Analysis",
      "Security Consulting",
    ],
  },
  {
    image: rocket,
    title: "Digital Engineering",
    color: "#000",
    description: [
      "Software Engineering",
      "UI Engineering",
      "UX Design",
      "Mobile Everything",
      "Data & Analytics",
      "Quality Engineering",
    ],
  },
  {
    image: scroll,
    title: "Intelligent Automation",
    color: "#C1C1C1",
    description: [
      "ML & AI",
      "Application Integration",
      "Process Management",
      "Internet of Things",
    ],
  },
  {
    image: cogbulb,
    title: "Infrastructure & Operations",
    color: "#A78C54",
    description: [
      "Cloud & DevOps",
      "Security Operations",
      "Application Support",
      "Infrastructure Support",
    ],
  },
]

export default function ServicesSection() {
  return (
    <div className={s.container}>
      <div className={s.grid}>
        {data?.map((obj, key) => (
          <Card key={key} obj={obj} />
        ))}
      </div>
      <Footer />
    </div>
  )
}

const Card = ({ obj }) => {
  return (
    <div className={s.card}>
      <div className={s.card_head}>
        <div className={s.card_color} style={{ backgroundColor: obj.color }}>
          <img className={s.card_image} src={obj.image} />
        </div>
        <div className={s.card_title}>
          <h3>{obj.title}</h3>{" "}
        </div>
      </div>
      <div className={s.card_content}>
        {obj.description.map(text => (
          <div className={s.card_text}>{text}</div>
        ))}
      </div>
    </div>
  )
}
