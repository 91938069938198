import React from "react"
import HeroSectionStyle from "./HeroSectionStyle.module.styl"
import Footer from "../../../../common/footer/Footer"

const HeroSection = () => {
  return (
    <section className={HeroSectionStyle.wrapper}>
      <div className={HeroSectionStyle.case_studies_holder}>
        <h2>
          Services
          <span className={HeroSectionStyle.hr} />
        </h2>

        <div className={HeroSectionStyle.content}>
          We deliver digital solutions that help organizations to solve complex
          business challenges and accelerate innovation, by partnering for the
          entire SDLC life cycle. We have four distinct service offerings to
          help leaders harness a cutting edge approach to design and technology.
          Here’s what each of them can offer to your business needs:
        </div>
      </div>
      <div className={HeroSectionStyle.image_holder} />
      <Footer />
    </section>
  )
}

export default HeroSection
