import React from "react"
import HeroSection from "./components/heroSection/HeroSection"
import NewsletterSection from "../newsletter/NewsletterSection"
import ServicesSection from "./components/services/ServicesSection"
import WorkSection from "../caseStudies/components/workSection/WorkSection"
import GridSection from "./components/grid/GridSection"

class ServicesPageTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      width: 0,
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth })
  }
  render() {
    return (
      <React.Fragment>
        <HeroSection />
        <ServicesSection />
        <GridSection />
        <WorkSection />
        <NewsletterSection />
      </React.Fragment>
    )
  }
}

export default ServicesPageTemplate

// <HookPointsOffers />
//         <OurWork />
//         <CaseStudiesImages
//           caseStudyImage1={caseStudy1}
//           caseStudyImage2={caseStudy2}
//           caseStudyImage3={caseStudy3}
//           caseStudyImage4={caseStudy4}
//           caseStudyImage5={caseStudy5}
//           caseStudyImage6={caseStudy6}
//           caseStudyImage7={caseStudy7}
//           caseStudyImage8={caseStudy8}
//           caseStudyImage9={caseStudy9}
//           caseStudyImage10={caseStudy10}
//           caseStudyImage11={caseStudy11}
//           caseStudyImage12={caseStudy12}
//           caseStudyImage13={caseStudy13}
//           caseStudyImage14={caseStudy14}
//           caseStudyImage15={caseStudy15}
//         />
//         <Testimonials />
//         <Clients>
//           <ClientReview
//             avatar={LaLaAvatar}
//             description={
//               "“Brendan speaks a language I understand. He makes navigating social media and standing out in crowded markets easy and effective. He’s become my go-to guy for all these things!”"
//             }
//             person_name={"LaLa Anthony"}
//             person_position={
//               "New York Times bestselling author, businesswoman, producer, and actress"
//             }
//           />
//           <ClientReview
//             avatar={GeyeAvatar}
//             color_white={true}
//             description={
//               "“To make a product, service, or yourself ‘stand out’ in a world of obnoxious clutter, everyone needs some type of competitive advantage. Hook Point and Brendan Kane, are master strategists intersecting technology and commerce, presents a logical and practical way to achieve that competitive advantage.”"
//             }
//             person_name={"Geyer Kosinski"}
//             person_position={"chairman and CEO of Media Talent Group"}
//           />
//         </Clients>

//         <Clients>
//           <ClientReview
//             color_white={width < 992 ? false : true}
//             avatar={FaiselAvatar}
//             description={
//               "“There is a small window to capture your audience’s attention today. Brendan refocuses your acquisition strategy on quality as opposed to quantity.”"
//             }
//             person_name={"Faisel Durrani"}
//             person_position={
//               "former GM/EVP of Def Jam Recordings and president of Marketing Live Nation"
//             }
//           />
//           <ClientReview
//             color_white={width < 992 ? true : false}
//             avatar={MichaelAvatar}
//             description={
//               "“In our highly distracted world, Brendan reveals how to create content and messages that make people pay attention.”"
//             }
//             person_name={"Michael Gervais"}
//             person_position={
//               "PhD, high-performance psychologist; host of the Finding Mastery podcast"
//             }
//           />
//         </Clients>

//         <Clients>
//           <ClientReview
//             avatar={JonJashniAvatar}
//             description={
//               "“What makes you or your product worth paying attention to? Brendan Kane will help you answer that question. His flashlight pierces the pop-cultural fog more effectively than anyone else’s.”"
//             }
//             person_name={"Jon Jashni"}
//             person_position={
//               "founder of Raintree Ventures; former president and chief creative officer of Legendary Entertainment, where he oversaw the development and production of movies such as Kong: Skull Island, Warcraft, Godzilla, Lost in Space, the Jackie Robinson biopic 42, and Pacific Rim"
//             }
//           />
//           <ClientReview
//             color_white={true}
//             avatar={SallyAvatar}
//             description={
//               "“If you want to become a lasting, successful brand online and off, Brendan delivers proven strategies that are sure to help you stand out and grab people’s attention.”"
//             }
//             person_name={"Sally Newell Cohen"}
//             person_position={
//               "senior vice president of global communications at ICANN; former COO of Toastmasters International"
//             }
//           />
//         </Clients>

//         <Clients>
//           <ClientReview
//             color_white={width < 992 ? false : true}
//             avatar={CyrusAvatar}
//             description={
//               "“In this new micro-attention world, it’s becoming harder and harder to stand out. Hook Point shares tried-and-true ways that get people to pay attention and is a must-read for anyone posting anything on social media today.”"
//             }
//             person_name={"Cyrus Gorjipour"}
//             person_position={"cofounder and CEO of Goalcast"}
//           />
//           <ClientReview
//             color_white={width < 992 ? true : false}
//             avatar={JonathanAvatar}
//             description={
//               "“Do you want to create demand for your brand or business? Brendan develops methods that work in the 24/7 ubiquitous world we live in.”"
//             }
//             person_name={"Jonathan Skogmo"}
//             person_position={"founder and CEO of Jukin Media"}
//           />
//         </Clients>

//         <Clients>
//           <ClientReview
//             avatar={CraigCelemensAvatar}
//             description={
//               "“The average American sees 4,000 ads a day, and who knows how many posts on social media. If you want to cut through the clutter and build a massive business or personal brand, Brendan Kane and Hook Point are your secret weapons.”"
//             }
//             person_name={"Craig Clemens"}
//             person_position={"cofounder of Golden Hippo"}
//           />
//           <ClientReview
//             color_white={true}
//             avatar={DorieClarkAvatar}
//             description={
//               "“Today we are bombarded with so many messages, it’s truly difficult to stand out. Luckily, Hook Point gives you the tools to capture attention, maintain it, and grow a compelling brand.”"
//             }
//             person_name={"Dorie Clark"}
//             person_position={
//               "author of Stand Out and executive education faculty, Duke University Fuqua School of Business"
//             }
//           />
//         </Clients>

//         <Clients>
//           <ClientReview
//             color_white={width < 992 ? false : true}
//             avatar={LathamAvatar}
//             description={
//               "“Brendan is relentless in his pursuit of finding new ways to help marketers do their job better.”"
//             }
//             person_name={"Latham Arneson"}
//             person_position={
//               "former VP of digital marketing at Paramount Pictures"
//             }
//           />
//           <ClientReview
//             color_white={width < 992 ? true : false}
//             avatar={MellisaAvatar}
//             description={
//               "“If you want to become a long-lasting, successful brand both online and off, look no further. Brendan helps you find the messages that get people to pay attention time and again.”"
//             }
//             person_name={"Melissa Ambrosini"}
//             person_position={
//               "bestselling author, speaker, podcaster, and entrepreneur"
//             }
//           />
//         </Clients>
//         <ClientReview
//           avatar={AjitAvatar}
//           description={
//             "“Brendan is a master at getting attention. If you feel you are in a crowded market and having a hard time standing out from the competition. Hook Point has tools and strategies you can use right away.”"
//           }
//           person_name={"Ajit Nawalkha"}
//           person_position={"cofounder, Evercoach and Mindvalley"}
//         />
//         <NewsletterSection />
