import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import ServicesPageTemplate from "../modules/services/ServicesPage.template"

const Services = ({ data }) => {
  return (
    <Layout>
      <SEO title="Services" />
      <ServicesPageTemplate
        caseStudy1={data.caseStudy1.edges}
        caseStudy2={data.caseStudy2.edges}
        caseStudy3={data.caseStudy3.edges}
        caseStudy4={data.caseStudy4.edges}
        caseStudy5={data.caseStudy5.edges}
        caseStudy6={data.caseStudy6.edges}
        caseStudy7={data.caseStudy7.edges}
        caseStudy8={data.caseStudy8.edges}
        caseStudy9={data.caseStudy9.edges}
        caseStudy10={data.caseStudy10.edges}
        caseStudy11={data.caseStudy11.edges}
        caseStudy12={data.caseStudy12.edges}
        caseStudy13={data.caseStudy13.edges}
        caseStudy14={data.caseStudy14.edges}
        caseStudy15={data.caseStudyDrStaci.edges}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    caseStudieLogos: allFile(
      filter: { relativeDirectory: { eq: "caseStudieLogos" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    caseStudy1: allFile(filter: { relativeDirectory: { eq: "caseStudy1" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy2: allFile(filter: { relativeDirectory: { eq: "caseStudy2" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy3: allFile(filter: { relativeDirectory: { eq: "caseStudy3" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy4: allFile(filter: { relativeDirectory: { eq: "caseStudy4" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy5: allFile(filter: { relativeDirectory: { eq: "caseStudy5" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy6: allFile(filter: { relativeDirectory: { eq: "caseStudy6" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy7: allFile(filter: { relativeDirectory: { eq: "caseStudy7" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy8: allFile(filter: { relativeDirectory: { eq: "caseStudy8" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy9: allFile(filter: { relativeDirectory: { eq: "caseStudy9" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy10: allFile(filter: { relativeDirectory: { eq: "caseStudy10" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy11: allFile(filter: { relativeDirectory: { eq: "caseStudy11" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy12: allFile(filter: { relativeDirectory: { eq: "caseStudy12" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy13: allFile(filter: { relativeDirectory: { eq: "caseStudy13" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudy14: allFile(filter: { relativeDirectory: { eq: "caseStudy14" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    caseStudyDrStaci: allFile(
      filter: { relativeDirectory: { eq: "caseStudyDrStaci" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    oneMillionFollowersImage: allFile(
      filter: { relativeDirectory: { eq: "oneMillionFollowersImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    caseStudyTylorSwiftImage: allFile(
      filter: { relativeDirectory: { eq: "caseStudyTylorSwiftImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    caseStudyRihannaImage: allFile(
      filter: { relativeDirectory: { eq: "caseStudyRihannaImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }

    yahooImage: allFile(filter: { relativeDirectory: { eq: "yahooImage" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default Services
