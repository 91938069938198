import React from "react"
import s from "./GridSectionStyle.module.styl"
import image1 from "../../../../assets/images/services/image1/Group 2927@2x.png"
import image2 from "../../../../assets/images/services/image2/arif-riyanto-G1N9kDHqBrQ-unsplash@2x.png"
import image3 from "../../../../assets/images/services/image3/Group 3608@2x.png"
import image4 from "../../../../assets/images/services/image4/marc-olivier-jodoin-NqOInJ-ttqM-unsplash@2x.png"

const data = [
  {
    title: "Strategy",
    subtitle: " & Advisory",
    desc:
      "Technology is evolving at a rapid pace. It is constantly responding to the challenges of tomorrow and leading in a market in flux. Having an experienced and trusted innovation partner is key to creating a business-relevant strategy and finding the right offerings in a growing sea of technologies.",
    color: "#FF0301",
    textColor: "#000000",
    image: image1,
  },
  {
    title: "Digital Engineering",
    subtitle: null,
    desc:
      "To adapt to today’s fast-changing markets and technologies, innovation through digital engineering has become a critical effort for businesses, helping them stand out among competitors and meet their customers’ evolving expectations. STS provides digital engineering services that enable organizations to operate, innovate and scale their business by leveraging cutting-edge technologies, top talent, and cross-industry expertise.",
    color: "#000000",
    textColor: "#FFFFFF",
    image: image2,
  },
  {
    title: "Intelligent Automation",
    subtitle: null,
    desc:
      "Intelligent automation plays an important role and can transform the way organizations do business through the convergence of RPA, AI, BPM, and application integration.Through our extensive experience and know-how in the latest technologies, we deliver end-to-end services helping organizations to optimize and scale their business, providing high data accuracy, speed, and workforce agility.",
    color: "#C1C1C1",
    textColor: "#000000",
    image: image3,
  },
  {
    title: "Infrastructure & Operations",
    subtitle: null,
    desc:
      "The constantly changing business environment has driven businesses to redesign some of their existing processes to meet the market’s needs. Altering or upgrading your business processes requires changing your underlying infrastructure, which serves as the foundation of all systems. Having a sustainable IT infrastructure is a must in the era of digital transformation. Build the foundations of a solid digital business with our Infrastructure services. Our team will help you plan, design, and implement an intelligent infrastructure, which drives high performance by following ISO standard paradigms.",
    color: "#A78C54",
    textColor: "#FFFFFF",
    image: image4,
  },
]

export default function GridSection() {
  return (
    <div className={s.container}>
      {data?.map((obj, key) => (
        <Card key={key} obj={{ ...obj, index: key }} />
      ))}
    </div>
  )
}

const Card = ({ obj }) => {
  return (
    <div className={s.card} style={{ backgroundColor: obj.color }}>
      <img
        style={{ order: 2 }}
        className={s.image}
        src={obj.image}
        color={obj.color}
      />
      <div
        style={{
          order: (obj.index + 1) % 2 == 0 ? 3 : 1,
        }}
        className={s.content}
      >
        <h3 style={{ color: obj.textColor }}>
          {obj.title}
          {obj.subtitle && (
            <span style={{ filter: "invert(1)" }}> {obj.subtitle} </span>
          )}
        </h3>
        <p style={{ color: obj.textColor }}>{obj.desc}</p>
      </div>
    </div>
  )
}
